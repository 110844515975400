import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // connect() {
  //   console.log("Spinner Controller Connected");
  //   this.spinner1 = document.querySelector("#spinner1");
  //   this.startAnimation();
  // }

  disconnect() {
    console.log("Spinner Controller Disconnected");
    this.stopAnimation();
  }

  startAnimation() {
    if (!this.spinner1) return; // Ensure the spinner element exists

    // Setup the spinner HTML content
    this.spinner1.innerHTML = this.spinnerHTML;

    // Start the animation loop
    this.animationInterval = setInterval(() => this.animate(), 400);
  }

  stopAnimation() {
    clearInterval(this.animationInterval);
    if (this.spinner1) {
      this.spinner1.innerHTML = "";
    }
  }

  animate() {
    const animationSteps = [
      "#B0008E", // Color para el primer paso
      "#B5BD00", // Color para el segundo paso
      "#0085CA"  // Color para el tercer paso
    ];

    // Incrementar stepIndex y resetearlo si supera el número de steps
    this.stepIndex = (this.stepIndex + 1) % animationSteps.length;

    const logoSticks = this.spinner1.querySelectorAll(".animation-sticks");
    logoSticks.forEach((svg, index) => {
      // Calcular el color basado en el stepIndex y ajustar el índice dentro del rango
      const color = animationSteps[(this.stepIndex + index) % animationSteps.length];
      svg.setAttribute("fill", color);
    });
  }

  connect() {
    this.spinner1 = document.querySelector("#spinner1");
    this.stepIndex = 0; // Inicializar stepIndex en el connect
    this.startAnimation();
    console.log('spinner frame')
  }



  get spinnerHTML() {
    return `
      <div class="d-flex align-items-center justify-content-center" style="position: fixed; z-index: 7; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);">
        <div class="text-center">
          <svg class="animation-sticks" width="17" height="92" viewBox="0 0 17 92" fill="#B0008E" xmlns="http://www.w3.org/2000/svg"><rect width="15.2398" height="91.05" rx="7.61989"/></svg>
          <svg class="animation-sticks" width="17" height="127" viewBox="0 0 17 127" fill="#B0B0B0" xmlns="http://www.w3.org/2000/svg"><rect width="15.2398" height="125.942" rx="7.61989"/></svg>
          <svg class="animation-sticks" width="17" height="92" viewBox="0 0 17 92" fill="#B0B0B0" xmlns="http://www.w3.org/2000/svg"><rect width="15.2398" height="91.05" rx="7.61989"/></svg>
        </div>
      </div>
    `;
  }
}
