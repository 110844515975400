import { Controller } from "@hotwired/stimulus"
const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

export default class extends Controller {
  async connect() {
    console.log("Probando geolocaiton");

    try {
      const pos = await this.getCurrentPosition();
      const crd = pos.coords;

      // console.log("Your current position is:");
      // console.log(`Latitude : ${crd.latitude}`);
      // console.log(`Longitude: ${crd.longitude}`);
      // console.log(`More or less ${crd.accuracy} meters.`);

      // Asignar los valores a los campos ocultos del formulario
      document.getElementById('lat').value = crd.latitude;
      document.getElementById('lng').value = crd.longitude;

      document.getElementById('btn-generar').disabled = false
    } catch (err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
  }

  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    });
  }


  // success(pos) {
  //   const crd = pos.coords;

  //   console.log("Your current position is:");
  //   console.log(`Latitude : ${crd.latitude}`);
  //   console.log(`Longitude: ${crd.longitude}`);
  //   console.log(`More or less ${crd.accuracy} meters.`);

  //   // Asignar los valores a los campos ocultos del formulario
  //   document.getElementById('lat').value = crd.latitude;
  //   document.getElementById('lng').value = crd.longitude;
  // }

  // error(err) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }

}
