import { Controller } from "@hotwired/stimulus";
const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};
// Connects to data-controller="geolocation"
export default class extends Controller {
  static values = {
    user: String,
    baseurl: String,
    deployedurl: String,
    cookies: Object
  };

  success = (pos) => {
    const crd = pos.coords;

    // console.log("Your current position is:");
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);
    // const dato = `${crd.latitude},${crd.longitude}`;
    this.lat = crd.latitude;
    this.long = crd.longitude;
  };

  error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  qrScan() {
    const scanner = new Html5QrcodeScanner("reader", {
      // Scanner will be initialized in DOM inside element with id of 'reader'
      qrbox: {
        width: 250,
        height: 250,
      }, // Sets dimensions of scanning box (set relative to reader element width)
      fps: 20, // Frames per second to attempt a scan
    });
    // console.log("scanner", scanner);
    scanner.render(
      (result) => {
        // Separar el result en los parámetros que necesita el post de validación de qr en recurso visita
        var pacienteId = result.split("|")[2];
        var userLocation = result.split("|")[1];
        var fechaAsignada = result.split("|")[4];
        var internacionLocation = userLocation;
        var efectorLocation = `${this.lat}, ${this.long}`;
        var distancia = 4;
        var body = {
          paciente_id: pacienteId,
          location_user: userLocation,
          location_internacion: internacionLocation,
          location_efector: efectorLocation,
          distancia_max_km: distancia,
          fecha_asignada: fechaAsignada,
        };
        var recursoVisitaId = result.split("|")[3];
        var efectorEspecialidad = this.element.dataset.especialidad;
        var efectorProfesion = this.element.dataset.profesion;
        var efectorId = this.data.get("efector");
        var fecha = this.data.get("fecha");
        var financiadora = this.element.dataset.financiadora;

        // console.log("recursoVisitaId", recursoVisitaId);
        // console.log("body", body);

        // console.log("user", localStorage.getItem("userToken"));
        // Hacer un fetch al post para obtener una respuesta de validación
        fetch(
          `${this.baseurlValue}/recurso-visita/${recursoVisitaId}/valid-qr`,
          {
            method: "POST", // Could be dynamic with Stimulus values
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${atob(this.value)}`,
              "Content-Type": "application/json",
              'role-type': this.cookiesValue.type,
              'entity-id': this.cookiesValue.entity_id,
              'owner': this.cookiesValue.owner
            },
            body: JSON.stringify(body),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("resultado validación", data.valido);
            if (data.valido) {
              if (
                window.location.hostname === "localhost" ||
                window.location.hostname === "127.0.0.1"
              ) {
                // Redirección en el entorno de desarrollo
                var baseUrl = "http://localhost:3000";
              } else {
                // Redirección en el entorno de producción
                var baseUrl = this.deployedurlValue;
              }
              // Con la respuesta de validación, ejecutar una acción (ok: Success!; falla: Ir a validación manual)
              document.getElementById("result").innerHTML =
              // <h2>La distancia entre el efector y el paciente es  ${data.distamce_km}km</h2>
              `
              <h2>Validación exitosa</h2>
              <div class="d-flex gap-3 justify-content-center">
                <p><a href="${baseUrl}/visitas_efector" class="text-white">Home</a></p>
                <p><a href="${baseUrl}/visitas_efector_registro/${data.visita_id}/registro?id_recurso_visita=${recursoVisitaId}&financiadora_id=${financiadora}&especialidad_id=${efectorEspecialidad}&profesion_id=${efectorProfesion}&efector_id=${efectorId}&fecha=${fecha}&visita_id=${data.visita_id}" class="text-white">Registro de visita</a></p>
              </div>
                `;
              const validacionManual =
                document.getElementById("validacionManual");
              validacionManual.remove();
            } else {
              if (data.distamce_km > this.distancia) {
                // <h2>La distancia entre el efector y el paciente es superior a ${this.distancia}km, distancia: ${data.distamce_km}km</h2>
                document.getElementById("result").innerHTML = `
              <h2>No es posible realizar la validación</h2>
              <h2>El efector no se encuentra en el mismo rango que el paciente</h2>
              <p>En caso que consideres esto un error puedes realizar la validación manual</p>
              `;
              } else {
                document.getElementById("result").innerHTML = `
                <h2>No es posible realizar la validación</h2>
                <h2>El efector no se encuentra en el mismo rango que el paciente</h2>
                <p>En caso que consideres esto un error puedes realizar la validación manual</p>
                `;
              }
            }
          });
        // Prints result as a link inside result element

        scanner.clear();
        // Clears scanning instance

        document.getElementById("reader").remove();
      },
      (error) => {
        // Utiliza una función flecha aquí
        console.error(error);
      }
    );
    this.disconnect();
  }

  connect() {
    console.log('id financiadora', this.element.dataset.financiadora)
    console.log('ESPECIALIDAD', this.element.dataset.especialidad)
    console.log('profesion', this.element.dataset.profesion)


    this.distancia=1
    this.value = this.data.get("token");
    // console.log("decoded", atob(this.value));
    navigator.geolocation.getCurrentPosition(this.success, this.error, options);
    let reader = document.getElementById("reader");
    if (reader) {
      this.qrScan();
    }
  }
}
