import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.formHasChanged = false;

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.handleTurboBeforeVisit = this.handleTurboBeforeVisit.bind(this);
    this.handleTurboSubmitStart = this.handleTurboSubmitStart.bind(this);

    $('form').on('change', 'input, select, textarea', this.handleFormChange);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    document.addEventListener("turbo:before-visit", this.handleTurboBeforeVisit);
    document.querySelector('form').addEventListener('turbo:submit-start', this.handleTurboSubmitStart);
  }

  disconnect() {
    $('form').off('change', this.handleFormChange);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    document.removeEventListener("turbo:before-visit", this.handleTurboBeforeVisit);
    // Asegúrate de que el formulario exista antes de intentar eliminar el event listener
    const form = document.querySelector('form');
    if (form) {
      form.removeEventListener('turbo:submit-start', this.handleTurboSubmitStart);
    }
  }

  handleFormChange() {
    this.formHasChanged = true;
  }

  handleBeforeUnload(e) {
    if (this.formHasChanged) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  handleTurboBeforeVisit(event) {
    if (this.formHasChanged && !confirm("Tienes cambios sin guardar. ¿Estás seguro de que quieres salir de esta página?")) {
      event.preventDefault();
    }
  }

  handleTurboSubmitStart() {
    this.formHasChanged = false;
    this.removeBeforeUnloadWarning();
  }

  removeBeforeUnloadWarning() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
}
