import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "message"]

  connect() {
  }

  validate() {
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
    const email = this.emailTarget.value
    const message = this.messageTarget

    if (email === '') {
      message.style.display = "none"
      message.innerText = ""
    } else if (emailRegex.test(email)) {
      message.style.display = "none"
      message.innerText = ""
    } else {
      message.style.display = "block"
      message.innerText = "El formato de correo no es válido"
    }
  }
}
