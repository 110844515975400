import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate", "error"]

  connect() {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0); // Establecer la hora a 0
    this.minDate = new Date(this.today);
    this.minDate.setDate(this.today.getDate() - 180); // Restar 180 días
  }

  validateStartDate() {
    const startDate = new Date(this.startDateTarget.value);

    if (startDate < this.minDate) {
      const minDateFormatted = `${String(this.minDate.getDate()).padStart(2, '0')}-${String(this.minDate.getMonth() + 1).padStart(2, '0')}-${this.minDate.getFullYear()}`;
      this.errorTarget.textContent = `La fecha de inicio no puede ser anterior a ${minDateFormatted}.`;
      this.startDateTarget.value = "";
    } else {
      this.errorTarget.textContent = "";
    }
  }

  validateEndDate() {
    const endDate = new Date(this.endDateTarget.value);
    const startDateValue = this.startDateTarget.value;

    if (endDate < (this.today + 1)) {
      this.errorTarget.textContent = "La fecha de finalización no puede ser anterior a hoy.";
      this.endDateTarget.value = "";
      return;
    }

    if (startDateValue) {
      const startDate = new Date(startDateValue);
      if (endDate < startDate) {
        this.errorTarget.textContent = "La fecha de finalización no puede ser anterior a la fecha de inicio.";
        this.endDateTarget.value = "";
      } else {
        this.errorTarget.textContent = "";
      }
    }
  }

  validateYearLength(event) {
    const dateValue = event.target.value;
    const year = dateValue.split('-')[0];

    if (year.length > 4) {
      alert("El año debe tener 4 dígitos.");
      event.target.value = "";
    }
  }

  validateDateRange(event) {
    console.log("validateDateRange called"); // Log para verificar que se llama

    // Obtener la fecha ingresada
    const inputDate = new Date(event.target.value);
    
    // Obtener las fechas mínima y máxima
    const minDate = new Date(this.startDateTarget.min); // Obtener min del campo de inicio
    const maxDate = new Date(this.startDateTarget.max); // Obtener max del campo de inicio

    // Verificar que minDate no sea mayor que maxDate
    if (minDate > maxDate) {
        this.errorTarget.textContent = "Error: La fecha mínima no puede ser mayor que la fecha máxima.";
        event.target.value = ""; // Limpiar el valor
        return; // Salir de la función
    }

    // Formatear fechas a d-m-Y
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0 indexados
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Verificar si la fecha ingresada está fuera del rango
    if (inputDate < minDate || inputDate > maxDate) {
        // Sumar 1 día solo en el mensaje de error para minDate
        const minDateWithOffset = new Date(minDate);
        minDateWithOffset.setDate(minDateWithOffset.getDate() + 1);

        const maxDateWithOffset = new Date(maxDate);
        maxDateWithOffset.setDate(maxDateWithOffset.getDate() + 1);

        this.errorTarget.textContent = `No puede ingresar una fecha fuera del rango ${formatDate(minDateWithOffset)} a ${formatDate(maxDateWithOffset)}.`;
        event.target.value = ""; // Limpiar el valor si está fuera de rango
        return; // Salir de la función si la fecha está fuera de rango
    }

    // Validar que la fecha final no sea menor que la fecha de inicio
    const startDateValue = this.startDateTarget.value;
    if (startDateValue) {
        const startDate = new Date(startDateValue);
        if (inputDate < startDate) {
            this.errorTarget.textContent = "La fecha de finalización no puede ser anterior a la fecha de inicio.";
            event.target.value = ""; // Limpiar el valor si es menor que la fecha de inicio
            return; // Salir de la función si la fecha final es inválida
        }
    }

    // Limpiar el mensaje de error si es válido
    this.errorTarget.textContent = "";
}


}
