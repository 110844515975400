// app/javascript/controllers/habilitar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recibioAfiliado", "recibidoPor"]

  connect() {
    this.toggleRecibidoPor()
  }

  toggleRecibidoPor() {
    const selectedValue = this.recibioAfiliadoTargets.find(input => input.checked)?.value
    if (selectedValue === 'No') {
      this.recibidoPorTarget.disabled = false
    } else {
      this.recibidoPorTarget.disabled = true
      this.recibidoPorTarget.value = ""
    }
  }
}
