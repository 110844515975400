import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-formats"
export default class extends Controller {
  static targets = [ "inputFile"]
  static values = { array: String }
  connect() {
    // console.log("diagnostico", this.diagnosticoTarget.children[0].children[0])
  }

  displayFileName(event) {
    const originalText = 'Diagnóstico adjunto'
    const input = event.currentTarget;
    const inputId = input.id;
    const fileType = JSON.parse(this.arrayValue)
    const fileName = input.files[0].name;
    const maxLength = 40;
    let truncatedFileName = fileName;
    // Find the error message element relative to the input element
    const errorMessage = input.closest('.input-file').parentElement.querySelector('.error-message');

    // Check if the file type received in the uploaded file is the same as the one in the fileType variable
    // filType is an array of accepted file types
    if (fileType) {
      const fileExtension = fileName.split('.').pop();
      const fileTypeMatch = fileType.find(type => type.toLowerCase() === fileExtension.toLowerCase());
      if (!fileTypeMatch) {
        errorMessage.textContent = `El archivo debe ser de tipo ${fileType.join(', ')}`;
        // empty the input and the text
        input.value = '';
        const label = document.querySelector(`label[for="${inputId}"]`);
        label.textContent = originalText;
        // Finish the function
        return;
      } else {

        errorMessage.textContent = '';
        if (fileName.length > maxLength) {
          truncatedFileName = fileName.substring(0, maxLength) + '...';
        }
        const label = document.querySelector(`label[for="${inputId}"]`);
        label.textContent = `${truncatedFileName}`;
        // Finish the function
        return;
      }
    }
  }
  hola() {
  }

  openFileDialog(event) {
    const id = this.inputFileTarget.children[0].children[0].id;
    var element = document.getElementById(id);
    // If the event target is the div
    if (event.target === event.currentTarget) {
      if(document.createEvent) {
        var evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, true);
        element.dispatchEvent(evt);
      }
      else {
        element.click();
      }
    }
  }

}
