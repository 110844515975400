import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // console.log("connect spinner_controller");
    this.spinner1 = document.querySelector("#spinner");
    if (this.spinner1) {
      document.addEventListener("turbo:before-visit", () => {
        this.animacion();
      });
    }
    if (this.spinner1) {
      document.addEventListener("turbo:before-fetch-request", () => {
        this.animacion();
      });
    }

    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
          // La página fue restaurada desde bfcache, ocultar el spinner si es necesario
          clearInterval(this.animationInterval);
          this.spinner1.innerHTML = "";
        }
      });
      this.stop()

  }

  animacion() {
    // console.log("turbo:before-fetch-request!");

    // Define logoSticks
    const svg1 = `<svg class="animation-sticks" width="17" height="92" viewBox="0 0 17 92" fill="#B0008E" xmlns="http://www.w3.org/2000/svg"><rect width="15.2398" height="91.05" rx="7.61989" transform="matrix(0.999969 0.00784273 -0.00790057 0.999969 1.52344 -0.0251465)"/></svg>`;
    const svg2 = `<svg class="animation-sticks" width="17" height="127" viewBox="0 0 17 127" fill="#B0B0B0" xmlns="http://www.w3.org/2000/svg"><rect width="15.2398" height="125.942" rx="7.61989" transform="matrix(0.999969 0.00784273 -0.00790057 0.999969 1.52344 -0.0251465)"/></svg>`;
    const svg3 = `<svg class="animation-sticks" width="17" height="92" viewBox="0 0 17 92" fill="#B0B0B0" xmlns="http://www.w3.org/2000/svg"><rect width="15.2398" height="91.05" rx="7.61989" transform="matrix(0.999969 0.00784273 -0.00790057 0.999969 1.52344 -0.0251465)"/></svg>`;

    // Insert SVG elements into this.spinner1 div
    this.spinner1.innerHTML = `
        <div class="d-flex align-items-center justify-content-center" id='spinner-block' style="position: fixed; z-index: 7; top: 50%; left: 50%; margin:auto; transform: translate(-50%, -50%); width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);">
          <div class="text-center" id="div2" style="">
            ${svg1}
            ${svg2}
            ${svg3}
          </div>
        </div>
        `;

    // Define the colors and timing for each part of the animation
    const animationSteps = [
      { color: "#B0008E", duration: 200 }, // Blue
      { color: "#B5BD00", duration: 400 }, // Pink
      { color: "#0085CA", duration: 400 }, // Yellow
    ];

    // Define a function to update the colors of the SVGs
    function updateColors(stepIndex) {
      const currentStep = animationSteps[stepIndex];
      const otherColor = "#B0B0B0"; // Gray
      const logoSticks = Array.from(document.querySelectorAll("#spinner svg"));
      // If current step matches logoStick index, set its active color, else set it to gray
      logoSticks.forEach((svg, index) => {
        const fill = stepIndex === index ? currentStep.color : otherColor;
        svg.setAttribute("fill", fill);
      });
    }

    // Define a function to run each step of the animation and use direction to go back and forth
    let stepIndex = 0;
    let direction = 1;
    function animate() {
      updateColors(stepIndex);
      stepIndex += direction;
      if (stepIndex >= animationSteps.length) {
        stepIndex = animationSteps.length - 2;
        direction = -1;
      } else if (stepIndex < 0) {
        stepIndex = 1;
        direction = 1;
      }
    }

    // Start the animation loop with the desired interval
    this.animationInterval = setInterval(animate, 400);
  }

  stop() {
      document.addEventListener("turbo:load", () => {
        // console.log('turbo: load')
        clearInterval(this.animationInterval);
        this.spinner1.innerHTML = "";
      });
      document.addEventListener("turbo:before-fetch-response", () => {
        clearInterval(this.animationInterval);
        this.spinner1.innerHTML = "";
      });

      document.addEventListener("DOMContentLoaded", () => {
        // console.log('domcl')
        clearInterval(this.animationInterval);
        this.spinner1.innerHTML = "";
      });
  }
}
