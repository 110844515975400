import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["campoB"];
  connect() {
  }
  // Validar al intentar escribir o enfocar el campo B
  validateCampoB(event) {
    const campoAValue = document.getElementById("campo_a").value;

    // Si el campo A está vacío, bloquear la escritura y mostrar advertencia
    if (campoAValue === "") {
      event.preventDefault(); // Bloquear la escritura
      this.showWarning();
    } else {
      this.hideWarning();
    }
  }

  // Método para cuando el campo A cambia
  checkCampoA(event) {
    console.log('campoa')
    const campoAValue = event.target.value; // Obtener el valor del campo A

    // Si el campo A tiene un valor seleccionado, habilitar el campo B y ocultar advertencia
    if (campoAValue !== "") {
      this.enableCampoB(); // Habilita el campo B para edición
      this.hideWarning(); // Oculta el mensaje de advertencia
    }
  }

  // Habilitar el campo B
  enableCampoB() {
    const campoB = this.campoBTarget;
    campoB.disabled = false; // Habilita el campo B
  }

  // Mostrar advertencia
  showWarning() {
    const warningMessage = document.getElementById("campo_b_warning");
    if (warningMessage) {
      warningMessage.style.display = "inline"; // Mostrar el mensaje
    }
  }

  // Ocultar advertencia
  hideWarning() {
    const warningMessage = document.getElementById("campo_b_warning");
    if (warningMessage) {
      warningMessage.style.display = "none"; // Ocultar el mensaje
    }
  }
}
