import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  updateMins(e) {
    const minutes = parseInt(e.target.value, 10)
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let result = '';
    if (hours > 0) {
      result += hours + ' hr' + (hours > 1 ? 's ' : ' ');
    }
    if (remainingMinutes > 0) {
      result += remainingMinutes + ' min';
    }
    let horaMin = this.element.querySelector('#horas-min')
    horaMin.innerText = result.trim()

  }
}
