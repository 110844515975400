import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:load", () => {
      this.inputs();
      this.tooltip();
      // Añadir event listeners una sola vez
      this.addEventListeners();
    });
  }

  addEventListeners() {
    // Agrega los event listeners a los campos de input, textareas y selects
    if (this.inputList) {
      this.inputList.forEach((input) => {
        input.addEventListener("input", () => this.validateInputs()); // Valida cuando hay cambios
      });
    }
  }

  inputs() {
    let tabList = document.querySelector(".tablist");
    this.continuar = document.querySelector(".continuar-btn");
    if (tabList) {
      let activeTab = tabList.querySelector('.tab[style*="block"]');
      this.inputList = activeTab.querySelectorAll("input.empty-validation, textarea.empty-validation, select.empty-validation");
    } else {
      this.inputList = document.querySelectorAll("input.empty-validation, textarea.empty-validation, select.empty-validation");
    }
    this.validateInputs(); // Realiza una validación inicial
  }

  validateInputs() {
    // Captura para el campo de tiempo
    let timeField = document.querySelector('#internacion_horario_visita');
    let isTimeFieldRelevant = this.isTimeFieldRelevant();

    if ((isTimeFieldRelevant && timeField && timeField.value.trim() !== '' && !/^([01]\d|2[0-3]):([0-5]\d)$/.test(timeField.value.trim())) ||
      (Array.from(this.inputList).some((input) => input.value.trim() === "")) || (Array.from(this.inputList).some(input => input.type === 'checkbox') && // Verifica si hay checkboxes
      !Array.from(this.inputList).some(checkbox => checkbox.type === 'checkbox' && checkbox.checked)) // Verifica si ningún checkbox está marcado
    ) {
      this.desactivarBoton();
      this.tooltip();
    } else {
      this.activarBoton();
      this.tooltip();
    }
  }

  // Validación para el campo de tiempo
  isTimeFieldRelevant() {
    // Lógica para determinar si timeField es relevante en el paso actual del formulario
    let activeTab = document.querySelector(".tab[style*='block']");
    return activeTab && activeTab.querySelector('#internacion_horario_visita') !== null;
  }


  activarBoton() {
    if (this.continuar) {
      this.continuar.disabled = false;
      this.continuar.style.opacity = 1;
    }
    var finalizarBTN = document.querySelector(".finalizar-btn");
    if (finalizarBTN) {
      finalizarBTN.disabled = false;
      finalizarBTN.parentElement.style.opacity = 1;
    }
  }

  desactivarBoton() {
    if (this.continuar) {
      this.continuar.disabled = true;
      this.continuar.style.opacity = 0.3;
    }
    var buttonFinalizar = document.querySelector(".finalizar-btn");
    if (buttonFinalizar) {
      buttonFinalizar.disabled = true;
      buttonFinalizar.parentElement.style.opacity = 0.4;
    }
  }

  tooltip() {
    var boton = document.getElementById("tooltip");
    var continuar = document.querySelector(".continuar-btn");
    if (continuar) {
      boton.addEventListener("mouseenter", function () {
        if (continuar.disabled === true) {
          boton.setAttribute(
            "title",
            "Debe completar todos los campos para poder continuar"
          );
        } else if (continuar.disabled === false) {
          boton.setAttribute("title", "Puede continuar");
        }
      });
    }
  }
}
