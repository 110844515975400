import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.updateActiveTab();
  }

  activate(event) {
    this.tabTargets.forEach(tab => {
      tab.classList.add('tab-title')
      tab.classList.remove('tab-title-active')
    });
    event.currentTarget.classList.toggle('tab-title-active');
  }

  updateActiveTab() {
    const currentUrl = window.location.href;
    this.tabTargets.forEach(tab => {
      if (currentUrl.includes(tab.dataset.urlFragment)) {
        tab.classList.add('tab-title-active')
        tab.classList.remove('tab-title')
      } 
    });
  }
}
