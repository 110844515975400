import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Spanish } from 'flatpickr/dist/l10n/es.js';
// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = ["calendario"];
  static values = {
    fechas: Array,
    totales: Array,
    baseurl: String,
  };
  connect() {
    console.log('baseurl',this.baseurlValue)
    // console.log("fechas", this.fechasValue);
    const fechas = this.totalesValue;
    const burl = this.baseurlValue;
    const arrayFechas = [];
    fechas.forEach((fecha) => {
      const fechaNueva = new Date(fecha);
      fechaNueva.setDate(fechaNueva.getDate());
      const opcionesFecha = { month: "long", day: "numeric", year: "numeric" };
      arrayFechas.push(fechaNueva.toLocaleDateString("en-US", opcionesFecha));
    });
    // console.log("arrayFechas", arrayFechas);
    const urlParams = new URLSearchParams(window.location.search);
    // Obtener el valor de un parámetro específico
    const fechaSeleccionada = urlParams.get('fecha');
    if (!this.flatpickrInstance) {
      this.flatpickrInstance = flatpickr(this.element, {
        enableTime: false,
        inline: true,
        locale: Spanish,
        dateFormat: "Y-m-d",
        defaultDate: fechaSeleccionada,
        onDayCreate: function (dObj, dStr, fp, dayElem) {
          // console.log(dayElem.dateObj.getFullYear());
          dayElem.addEventListener("click", function (event) {
            event.preventDefault(); // Evitar el comportamiento predeterminado del clic
            // Puedes realizar aquí cualquier acción personalizada en lugar de la selección predeterminada
            // console.log(dayElem);
            const meses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            const mes = meses[dayElem.dateObj.getUTCMonth()];
            const dia = dayElem.dateObj.getDate();
            const año = dayElem.dateObj.getFullYear();
            const fecha = `${año}-${mes}-${dia}`;

            if (
              window.location.hostname === "localhost" ||
              window.location.hostname === "127.0.0.1"
            ) {
              // Redirección en el entorno de desarrollo
              var baseUrl = "http://localhost:3000";
            } else {
              // Redirección en el entorno de producción
              var baseUrl = burl;
            }
            window.location.href = `${baseUrl}/portal_pacientes/calendario/${fecha}`;
          });
        },
        onMonthChange: function (selectedDates, dateStr, instance) {
          // console.log("onchange")
          const calendarContainer = instance.calendarContainer;
          const days = calendarContainer.querySelectorAll(".flatpickr-day");
          days.forEach(function (day) {
            const fecha = day.getAttribute("aria-label");
            if (arrayFechas.includes(fecha)) {
              day.classList.add("destacado");
            }
          });
        },
        onReady: function (selectedDates, dateStr, instance) {
          const calendarContainer = instance.calendarContainer;
          const days = calendarContainer.querySelectorAll(".flatpickr-day");
          days.forEach(function (day) {
            const fecha = day.getAttribute("aria-label");
            if (arrayFechas.includes(fecha)) {
              day.classList.add("destacado");
            }
          });
        },
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("onchange");
          const calendarContainer = instance.calendarContainer;
          const days = calendarContainer.querySelectorAll(".flatpickr-day");
          days.forEach(function (day) {
            const fecha = day.getAttribute("aria-label");
            if (arrayFechas.includes(fecha)) {
              day.classList.add("destacado");
            }
          });
        },
      });
    }
  }

  disconnect() {
    // Destruye la instancia de Flatpickr al desconectar el controlador
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }
}
