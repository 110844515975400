import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Spanish } from 'flatpickr/dist/l10n/es.js';
// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = ["calendario"];
  static values = {
    fechas: Array,
  };
  connect() {
    // console.log("fechass", this.fechasValue);
    const fechas = this.fechasValue;
    const arrayFechas = [];
    fechas.forEach((fecha) => {
      const fechaNueva = new Date(fecha);
      fechaNueva.setDate(fechaNueva.getDate() + 1);
      const opcionesFecha = { month: "long", day: "numeric", year: "numeric" };
      arrayFechas.push(fechaNueva.toLocaleDateString("en-US", opcionesFecha));
    });
    // console.log("arrayFechas", arrayFechas);
    new flatpickr(this.element, {
      enableTime: false,
      inline: true,
      locale: Spanish,
      dateFormat: "Y-m-d",
      onMonthChange: function (selectedDates, dateStr, instance) {
        // console.log("onchange")
        const calendarContainer = instance.calendarContainer;
        const days = calendarContainer.querySelectorAll(".flatpickr-day");
        days.forEach(function (day) {
          const fecha = day.getAttribute("aria-label");

          day.style.setProperty("pointer-events", "none", "important");
          // console.log("day", day);
          if (arrayFechas.includes(fecha)) {
            day.classList.add("destacado");
          }
        });
      },
      onReady: function (selectedDates, dateStr, instance) {
        const calendarContainer = instance.calendarContainer;
        const days = calendarContainer.querySelectorAll(".flatpickr-day");
        days.forEach(function (day) {
          day.style.setProperty("pointer-events", "none", "important");
          const fecha = day.getAttribute("aria-label");
          if (arrayFechas.includes(fecha)) {
            day.classList.add("destacado");
          }
        });
      },
    });
  }
}
