import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['replaceTemplate', 'linkModulos']
  connect() {
  }

  replaceModuloContent(e){
    if (e.target.value != '') {
      const id = e.target.value
      const url = `/modulos_edit/${id}/edit?boton_remove_plantilla=true`; // Construir la URL con el ID
      this.replaceTemplateTarget.setAttribute('href', url); // Actualizar el href del enlace

      // Simular el clic en el enlace
      this.replaceTemplateTarget.click()
    } else {
      const botonNew = document.querySelector('#vista-new-mod-personalizado')
      // const url = `/modulos/new?boton_remove_plantilla=true`
      console.log('botonNew', botonNew)
      // this.replaceTemplateTarget.setAttribute('href', url); // Actualizar el href del enlace

      // // Simular el clic en el enlace
      // this.replaceTemplateTarget.click()
      botonNew.click()
    }
  }

  updateModuloOptions() {

  }
}
