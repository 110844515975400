import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    titulo: String,
    confirm: String,
    mensajePersonalizado: String 
  }
  connect() {
    // this.element.textContent = "Hello World!"

  }

  submit(event) {
    // Asegúrate de que esto se ajusta al ID de tu formulario
    event.preventDefault();
    // Mostrar el modal de Bootstrap
    this.showModal()
  }

  showModal() {
    const modal = document.getElementById('confirmation-modal')
    modal.innerHTML = `
    <div data-controller="turbo-modal confirmation-modal" data-turbo-modal-target="modal" data-action="turbo:submit-end->turbo-modal#submitEnd keyup@window->turbo-modal#closeWithKeyboard" class="modal confirm-modal d-block">
      <div class="modal-backdrop" style="overflow-y: scroll !important" role="document">
        <div class="modal-big px-3 py-3 modal-closing" id="div-modal" style="background: white; width: 480px; padding: 0 !important; padding-top: 15px !important;">
          <div class="modal-content" style="border-bottom: 1px solid #dee2e6; border-top: none; border-radius: 0px; border-left: none; border-right: none;">
            <div class="d-flex justify-content-end pe-2">
              <button type="button" class="btn-close" data-action="turbo-modal#hideModal">
                <span aria-hidden="true"></span>
              </button>
            </div>
            <h5 class="mb-3 modal-header-title mx-auto">${this.tituloValue}</h5>
          </div>
          <div>
            <div class="modal-body mt-3 px-4 d-flex flex-column align-items-center" style="margin-bottom: 1.4rem;">
              <img src="/assets/icons/icon-delete.svg" class="mb-2" alt="Icono Eliminar">
              <p class="text-center p-modal p-4" style="padding-left: 0 !important; padding-right: 0 !important;">¿Está seguro que desea ${this.tituloValue.toLowerCase()}?</p>
              <!-- Aquí se agrega el mensaje personalizado -->
              <p class="text-center" style="padding-left: 0 !important; padding-right: 0 !important;">${this.mensajePersonalizadoValue}</p>
            </div>
            <div class="modal-footer d-flex mt-5 p-0" style="height: 4rem">
              <div class="col-6 d-flex justify-content-center align-items-center m-0 h-100" style="cursor: pointer;">
                <button data-action='click->confirmation-modal#confirm' id= "${this.confirmValue}" type="button" class="confirmar-si w-100 border-0 d-flex justify-content-center h-100 align-items-center" style='background: none;'>
                  <span aria-hidden="true" class="modal-span my-auto" style="color: black;">Sí</span>
                </button>
              </div>
              <div class="col-6 d-flex justify-content-center align-items-center m-0 h-100" style="border-left: 1px solid #dee2e6; cursor: pointer;" data-action="turbo-modal#hideModal">
                <button type="button" class="w-100 border-0 d-flex justify-content-center h-100 align-items-center" data-action="turbo-modal#hideModal" style='background: none;'>
                <span aria-hidden="true" class="modal-span my-auto" style="color: black;">No</span>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <style>
    .confirm-modal {
      .scrollableDiv {
        height: 340px !important; /* Ajusta la altura máxima según tus necesidades */
        overflow-y: auto; /* Habilita el desplazamiento vertical si es necesario */
      }
      .btn-close {
      }
      .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.7) !important;
      }
      .modal-big {
        background: $grey-scale-00;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        position: relative;
        top: 100px;
        left: 50%;
        transform: translate(-50%);
        max-width: 55rem;
        margin-left: 38px; // aligning for xl screen with TRADITUM logo
        padding: 30px;
        .label-input-pair {
          width: 430px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          label {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: $grey-strong;
          }
          input[type="radio" i] {
            width: 106px;
          }
        }
        #input-75 {
          width: 667px;
          input {
            width: 487px;
            height: 40px;
          }
        }
        #input-100 {
          width: 931px;
          input {
            width: 751px;
            height: 85px;
          }
        }
      }
      .modal-header, .modal-body {
        padding: 0px !important;
      }
      .modal-content {
        height: 100%;
      }
      .modal-span {
        pointer-events: none
      }
    }
  </style>
    `
  }
  confirm(e) {
    document.getElementById(e.target.id).click()
    document.getElementById('confirmation-modal').innerHTML = ''
  }
}
