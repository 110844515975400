import { Controller } from "@hotwired/stimulus"

// Definición del controlador Stimulus
export default class extends Controller {
  static targets = ["submitButton", "errorMessage", "inputField", "retroceso"]

  connect() {
    this.updateButtonState()
    this.element.addEventListener('input', () => this.updateButtonState())
    this.element.addEventListener('submit', (event) => this.handleSubmit(event))
    this.retrocesoTarget.addEventListener('click', () => history.back())
  }

  checkInputs() {
    const inputs = this.inputFieldTargets
    let valid = false

    inputs.forEach(input => {
      if (input.type === 'checkbox') {
        if (input.checked) {
          valid = true
        }
      } else if (input.type === 'text' || input.type === 'number') {
        if (input.value.trim() !== "") {
          valid = true
        }
      } else if (input.tagName === 'TEXTAREA') {
        if (input.value.trim() !== "") {
          valid = true
        }
      }
    })

    return valid
  }

  updateButtonState() {
    const isValid = this.checkInputs()

    if (isValid) {
      this.submitButtonTarget.classList.remove("disabled-btn")
      this.submitButtonTarget.removeAttribute("disabled")
      this.submitButtonTarget.style.setProperty('background-color', '#0054AD', 'important')
      this.submitButtonTarget.style.setProperty('opacity', '1', 'important');
      this.errorMessageTarget.style.display = 'none'
    } else {
      this.submitButtonTarget.classList.add("disabled-btn")
      this.submitButtonTarget.setAttribute("disabled", true)
      this.submitButtonTarget.style.setProperty('opacity', '0.5', 'important');
      this.errorMessageTarget.style.display = 'block'
    }
  }

  handleSubmit(event) {
    const isValid = this.checkInputs()
    if (!isValid) {
      event.preventDefault()
      this.errorMessageTarget.style.display = 'block'
    } else {
      this.errorMessageTarget.style.display = 'none'
    }
  }
}
