import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // document.addEventListener('turbo:load', () => {
    //   this.element.classList.remove("form-control")
    //   new TomSelect(`#${this.element.id}`, {
    //     plugins: {
    //       remove_button: {
    //         title: 'Remove this item',
    //       }
    //     },
    //     create: false,
    //     sortField: {
    //       field: "text",
    //       direction: "desc"
    //     }
    //   });
    // })
    // document.querySelector(`#${this.element.id}`).nextElementSibling.querySelector('.ts-control').querySelector('input').classList.add('empty-validation');

  }

  connect() {
    // console.log(this.element)
    if (!this.element.tomselect) {
      this.initializeTomSelect();
    }
  }

  initializeTomSelect() {
    this.element.classList.remove("form-control")
    // console.log('EDIT TRUE', this.element.dataset.edit)
    if (this.element.id) {
      if (!this.element.tomselect) {
        new TomSelect(this.element, {
          plugins: {
            remove_button: {
              title: 'Remove this item',
            }
          },
          create: false,
          sortField: {
            field: "text",
            direction: "desc"
          },
          render: {
            option: function (data, escape) {
              // Resaltar la opción deseada
              const highlightedValue = 'create_custom_module'; // Cambia esto al valor que quieres resaltar
              const className = data.value === highlightedValue ? 'text-primary fw-bold' : '';

              // Obtener y parsear los datos de atributos data-*
              const recursos = data.recursos ? JSON.parse(data.recursos) : [];
              const insumos = data.insumos ? JSON.parse(data.insumos) : [];
              const equipamientos = data.equipamiento ? JSON.parse(data.equipamiento) : []

              // Formatear los datos para mostrar en el title
              const recursosText = recursos.length > 0 ? recursos.map(recurso =>
                `${recurso[0]} - ${recurso[1]}hs - Cant: ${recurso[2]} - Por: ${recurso[3]}${recurso[4] ? ' - ' + recurso[4] : ''}`
              ).join('<br>') : 'No hay recursos';

              const insumosText = insumos.length > 0 ? insumos.map(insumo =>
                `${insumo[2]} - Cant: ${insumo[1]} - Por: ${insumo[0]}`
              ).join('<br>') : 'No hay insumos';

              const equipamientosText = equipamientos.length > 0 ? equipamientos.map(equip =>
                `${equip[0]}`
              ).join('<br>') : 'No hay equipamiento';

              // Crear el HTML para mostrar la opción
              return `<div class="option ${className}"
                          title="RECURSOS:\n${recursosText}\nINSUMOS:\n${insumosText}\nEQUIPAMIENTOS:\n${equipamientosText}"
                          data-value="${escape(data.value)}">
                          ${escape(data.text)}
                      </div>`;
            }
          }
        });
      }
    }
  }

  refresh() {
    if (this.element.tomselect) {
      this.element.tomselect.destroy();
    }
    this.initializeTomSelect();
  }
}
