// app/javascript/controllers/flatpickr_simple_controller.js
import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Spanish } from 'flatpickr/dist/l10n/es.js';


export default class extends Controller {
  static targets = ["calendar"]

  connect() {
    flatpickr(this.calendarTarget, {
      enableTime: false,
      dateFormat: "Y-m-d",
      inline: true, // Muestra el calendario siempre visible
      onChange: this.setDateAndSubmit.bind(this), // Llama a la función cuando se selecciona una fecha y luego envía el formulario
      onReady: this.hideCalendarArrows.bind(this),
      locale: Spanish,
    });


  }

  hideCalendarArrows() {
    const prevArrow =document.querySelectorAll(".flatpickr-prev-month");
    const nextArrow =document.querySelectorAll(".flatpickr-next-month");
    if (prevArrow) prevArrow.forEach((arrow) => { arrow.style.display = "none" });
    if (nextArrow) nextArrow.forEach((arrow) => { arrow.style.display = "none" });
  }

  // Este método se llama cuando se selecciona una fecha y realiza el submit automáticamente
  setDateAndSubmit(selectedDates, dateStr) {
    this.calendarTarget.dataset.selectedDate = dateStr;
    this.submit(); // Llama al método para enviar el formulario
  }

  // Método que captura el valor al enviar el formulario
  submit() {
    const form = this.calendarTarget.closest('form');
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.calendarTarget.id.replace('_calendar', ''); // Usar el nombre del filtro
    input.value = this.calendarTarget.dataset.selectedDate; // Asignar el valor de la fecha seleccionada

    form.appendChild(input);
    form.submit(); // Enviar el formulario automáticamente
  }
}
