import { Controller } from "@hotwired/stimulus";
// import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["ciudadSelect"];
  static values = {
    cookies: Object
  }
  baseUrl = document.querySelector('meta[name="base-url"]').getAttribute('content');

  connect() {
    // console.log("¡Hola desde nuestro primer controlador Stimulus!");
    // console.log("COOKIES ccess", Cookies.get('access_token'));
    // console.log("cookies profile", this.cookiesValue);
  }

  seleccionarCiudad() {
    // Obtener los elementos del select de provincia
    this.provinciaSelectInternacion = document.getElementById("provinciaSelect");
    this.provinciaSelectEfector = document.getElementById("provinciaSelect2");

    // Determinar cuál select de provincia está siendo utilizado
    if (this.provinciaSelectInternacion) {
      this.provinciaSeleccionada = this.provinciaSelectInternacion.value;
    } else if (this.provinciaSelectEfector) {
      this.provinciaSeleccionada = this.provinciaSelectEfector.value;
    }

    // Si no hay una provincia seleccionada, salir
    if (!this.provinciaSeleccionada) return;

    // Opciones para la solicitud HTTP
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get('access_token')}`,
        'role-type': this.cookiesValue.type,
        'entity-id': this.cookiesValue.entity_id,
        'owner': this.cookiesValue.owner
      }
    };

    // Realizar la solicitud para obtener las ciudades
    fetch(`${this.baseUrl}/estados/${this.provinciaSeleccionada}/ciudades`, options)
      .then(response => response.json())
      .then(data => {
        const divCiudades = document.getElementById("ciudad");

        // Destruir TomSelect si ya está inicializado
        if (divCiudades.tomselect) {
          divCiudades.tomselect.destroy();
        }

        // Remover clases innecesarias
        divCiudades.classList.remove(
          "icon-select",
          "form-control",
          "input-paciente-datos"
        );

        // Limpiar el contenido del select
        divCiudades.innerHTML = '<option value="">Seleccione ciudad</option>';

        // Actualizar las opciones del select según los datos obtenidos
        if (data.status_code !== 404 && data.length > 0) {
          data.forEach(city => {
            const option = document.createElement("option");
            option.value = city.id;
            option.textContent = city.nombre;
            divCiudades.appendChild(option);
            divCiudades.classList.add("enabled-pointer");
          });
        } else {
          // Si no hay ciudades, mostrar una opción indicando que no hay datos
          const noCitiesOption = document.createElement("option");
          noCitiesOption.value = "";
          noCitiesOption.textContent = "No hay ciudades";
          divCiudades.appendChild(noCitiesOption);
        }

        // Re-inicializar TomSelect
        this.tomSelect('#ciudad');

        // Eliminar la clase 'edit-icon-input' de los selects con la clase 'ts-wrapper'
        const selects = document.querySelectorAll('.ts-wrapper');
        if (selects.length > 0) {
          selects.forEach(select => {
            select.classList.remove("edit-icon-input");
          });
        }

        // Añadir la clase 'custom-placeholder-dark' al control de TomSelect
        const input = document.querySelector('#ciudad-ts-control');
        if (input) {
          input.classList.add("custom-placeholder-dark");
        }
      })
      .catch(error => console.error('Error al obtener las ciudades:', error));
  }


  tomSelect(id) {
    this.tomSelectInstancenew = new TomSelect(id, {
      create: false,
      sortField: {
        field: "text",
        direction: "asc",
      },
    });
  }

  blanqueoPorPais() {
    this.ciudad = document.getElementById('ciudad');
    var paisSelectInt = document.getElementById('internacion_pais');
    var paisSelectRfector = document.getElementById('pais_efector');
    this.provinciaSelectInternacion = document.getElementById("provinciaSelect");
    this.provinciaSelectEfector = document.getElementById("provinciaSelect2");
    this.ciudad = document.getElementById("ciudad");
    if (this.provinciaSelectInternacion) {
      this.ciudad.disabled = (paisSelectInt.value === "");
      this.provinciaSelectInternacion.disabled = (paisSelectInt.value === "");
      this.provinciaSelectInternacion.value = "";
    } else if (this.provinciaSelectEfector) {
      this.ciudad.disabled = (paisSelectRfector.value === "");
      this.provinciaSelectInternacion.disabled = (paisSelectRfector.value === "");
      this.provinciaSelectEfector.value = "";
    }
    console.log(this.tomSelectInstancenew)
    if (this.tomSelectInstancenew) {
      if(paisSelectInt.value === "") {
        this.tomSelectInstancenew.setValue("");
        this.tomSelectInstancenew.clearOptions();
      };
    }
  }
}
